import $ from "jquery";
import "jquery-match-height";
import objectFitImages from "object-fit-images";
import "owl.carousel";
import { createBrowserHistory } from "history";
import "@fancyapps/fancybox";
import lity from "lity/dist/lity.min.js";

import "./lazyload";
import "./map";
import "./carousel";
import "./countdown";
import "./page-list-plus";
// import "./hide-snowflakes";


import Cookies from 'js-cookie'

const isTablet = function isTablet() {
  return $(window).width() <= 768;
};

$(document).ready(() => {

  // Init infoModal
  if($('#informationModal').length >0 ){
    $(document).on('lity:close', function(event, instance) {
      Cookies.set('information-shown', true, { expires: 30 });
    });
    if (!Cookies.get('information-shown')) {
      var lightbox = lity('#informationModal');
      $('#closeBtn').on('click', function(e) {
          lightbox.close();
          Cookies.set('information-shown', true, { expires: 30 });
      });
      $('.modal-btn').on('click', function(e) {
          Cookies.set('information-shown', true, { expires: 30 });
      });
    }
  }

  objectFitImages();

  $(".match--height").matchHeight();
  $(".match--height-second").matchHeight();
  $(".match--height-third").matchHeight();

  $(".press__item")
    .stop()
    .on("click", function (e) {
      e.preventDefault();

      const id = $(this).attr("data-id");

      $(".press__images").html("");

      $.ajax({
        type: "GET",
        url:
          /* eslint-disable */ CCM_APPLICATION_URL /* eslint-enable */ +
          "/press/images/" +
          id,
        error: function (e) {
          console.log(e);
        },
        success: function (r) {
          $.each(r.images, function (index, value) {
            $(".press__images").append(
              '<a href="' +
                value.src +
                '" class="press--image grid__col--6 grid__col--sm--12 relative height--small" data-fancybox="preview" data-selectable="true">' +
                '<img class="image--cover height--small width--stretch" src="' +
                value.thumbnail +
                '" alt="' +
                value.title +
                '" />' +
                '<div class="press__overlay width--stretch height--stretch float--left absolute background--black background--transparent background--light"></div>' +
                '<i class="fa fa-search" aria-hidden="true"></i>' +
                "</a>"
            );
          });
        },
      });
    });

  $(".press--image").fancybox({
    thumbs: {
      autoStart: false,
    },
    buttons: [
      "zoom",
      "share",
      "slideShow",
      "fullScreen",
      "download",
      "thumbs",
      "close",
    ],
  });

  $(".slider--link").fancybox({
    loop: true,
    buttons: ["zoom", "share", "slideShow", "close"],
  });

  $(".press__item").on("click", function () {
    $(this).addClass("active").siblings().removeClass("active");
  });

  /**
   * Responsive navigation
   */

  const responsiveNav = function responsiveNav() {
    $(".header__navicon")
      .stop()
      .on("click", function (e) {
        e.preventDefault();
        $(this).toggleClass("header__navicon--open");

        if ($(this).hasClass("header__navicon--open")) {
          $(".header__nav").animate(
            {
              right: "0",
            },
            250
          );

          $(".page, .header__logo, .header__navicon").animate(
            {
              right: "250px",
            },
            250
          );

          $("body").addClass("disable-scrolling");

          document.ontouchmove = function (event) {
            var isTouchMoveAllowed = true,
              target = event.target;

            while (target !== null) {
              if (
                target.classList &&
                target.classList.contains("disable-scrolling")
              ) {
                isTouchMoveAllowed = false;
                break;
              }
              target = target.parentNode;
            }

            if (!isTouchMoveAllowed) {
              event.preventDefault();
            }
          };
        } else {
          $(".header__nav").animate(
            {
              right: "-250px",
            },
            250
          );

          $(".page, .header__logo, .header__navicon").animate(
            {
              right: "0",
            },
            250
          );

          $("body").removeClass("disable-scrolling");
        }
      });

    $(".header__item--arrow")
      .stop()
      .on("click", function (e) {
        e.preventDefault();
        $(this).toggleClass("rotate");
        $(this).parent().find(".header__sublist").stop().slideToggle();
      });
  };

  responsiveNav();

  $(window).resize(function () {
    if (!isTablet()) {
      $("body").removeClass("disable-scrolling");
    }
  });

  if ($(".page").attr("data-collection-level") == 0) {
    $(".header__logo")
      .stop()
      .on("click", function (e) {
        if ($(".page").attr("data-collection-id") != 151) {
          e.preventDefault();
        }
        $("html, body").animate(
          {
            scrollTop: "0px",
          },
          1000
        );
      });
  }

  /* eslint-disable */
  const history = createBrowserHistory();
  /* eslint-enable */

  /**
   * Next element after fixed header gets margin top header height
   */
  var headerNextMarginTop;

  if ($("html").hasClass("ccm-toolbar-visible")) {
    headerNextMarginTop = $(".header").outerHeight() + 48;
  } else {
    headerNextMarginTop = $(".header").outerHeight();
  }

  $(".header").next().css({
    "margin-top": headerNextMarginTop,
  });

  /**
   * Footer always sticks at the bottom of the page
   */

  var footerHeight;

  const stickyFooter = function stickyFooter() {
    footerHeight = $(".footer").outerHeight();
    $(".page__wrap").css({ "min-height": $(window).height() - footerHeight });
  };

  $(window).bind("resize", stickyFooter);
  stickyFooter();

  /**
   * Block settings
   */

  const blockSettings = function blockSettings() {
    $(".section__block").each(function () {
      /**
       * if section block has no form
       */

      if ($(this).find(".section__inner--form").children().length == 0) {
        $(this).find(".section__inner--form").parent().hide();
      }

      /**
       * if section block has no container
       */

      // if ($(this).hasClass('grid--no-container')) {
      //   $(this).parents('.grid__container').css({
      //     'max-width':'100%',
      //     'padding':'0'
      //   });
      // }

      if ($(this).find(".section__inner img").length) {
        $(this).find(".section__inner").css({
          display: "table",
        });
      }

      /**
       * if block has background image without content (responsive)
       */

      if (isTablet()) {
        if ($(this).find(".section__inner").children().length == 0) {
          $(this).find(".background--image").css({
            position: "relative",
            "min-height": "500px",
          });

          $(this).find(".section__inner").remove();
        }
      } else {
        if ($(this).find(".section__inner").children().length == 0) {
          $(this).find(".background--image").css({
            position: "absolute",
            "min-height": "auto",
          });

          if ($(this).hasClass("grid__col--12")) {
            $(this).css({
              "min-height": "500px",
            });
          }

          if ($(this).hasClass("hero__section")) {
            $(this).css({
              "min-height": "450px",
            });
          }
        }
      }
    });
  };

  $(window).bind("resize", blockSettings);
  blockSettings();

  // Select all links with hashes
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        window.location.pathname.replace(/^\//, "") ===
          this.pathname.replace(/^\//, "") &&
        window.location.hostname === this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $("html, body").animate(
            {
              scrollTop: target.offset().top - 135,
            },
            1000,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    });

  /**
   * Form inputs
   */

  $("input:file").change(function () {
    var fileNameComponents = $(this).val().split("\\");
    var fileName = fileNameComponents[fileNameComponents.length - 1];
    $("input:file")
      .prev()
      .append(
        '<span class="color--black"><strong>' + fileName + "</strong></span>"
      );
  });
});

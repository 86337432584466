/* eslint-disable */
$(document).ready(() => {
  /**
   * Google Maps with marker
   */

  var map;

  function initMap() {
    var iconURLPrefix =
      CCM_APPLICATION_URL + "/packages/moeke/themes/moeke/assets/images/";

    var markerIcon = {
      url: iconURLPrefix + "icon-marker.svg",
      scaledSize: new google.maps.Size(30, 30),
    };

    var markerIconLength = markerIcon.length;

    map = new google.maps.Map(document.getElementById("map"), {
      zoom: 12,
      center: new google.maps.LatLng(
        markerLocation.latitude,
        markerLocation.longitude
      ),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      scrollwheel: false,
      panControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
    });

    var infowindow = new google.maps.InfoWindow();

    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(
        markerLocation.latitude,
        markerLocation.longitude
      ),
      map: map,
      icon: markerIcon,
    });

    infowindow.setContent(
      '<div class="infowindow">' +
        "<h5>" +
        markerLocation.name +
        "</h5>" +
        "<small>" +
        markerLocation.address +
        "</small>" +
        "<small>" +
        markerLocation.zip +
        ", " +
        markerLocation.city +
        "</small>" +
        "<br />" +
        '<small><a href="tel:' +
        markerLocation.phone +
        '">' +
        markerLocation.phone +
        "</a></small>" +
        '<small><a href="mailto:' +
        markerLocation.email +
        '">' +
        markerLocation.email +
        "</a></small>" +
        "</div>"
    );
    infowindow.open(map, marker);

    google.maps.event.addListener(
      marker,
      "click",
      (function () {
        return function () {
          infowindow.open(map, marker);
        };
      })(marker)
    );
  }

  if ($("#map").length) {
    initMap();
    google.maps.event.addDomListener(window, "resize", function () {
      var center = map.getCenter();
      google.maps.event.trigger(map, "resize");
      map.setCenter(center);
    });
  }
});
/* eslint-enable */

// Carousel Blocks

$(".carousel--blocks").owlCarousel({
  autoplay: false,
  dots: true,
  nav: true,
  navText: [
    "<i class='fas fa-chevron-left'></i>",
    "<i class='fas fa-chevron-right'></i>",
  ],
  margin: 15,
  loop: true,
  responsiveClass: true,
  responsive: {
    0: {
      items: 1,
    },
    800: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
});

$(".carousel--full-width").owlCarousel({
  autoplay: false,
  animateOut: "fadeOut",
  dots: true,
  nav: true,
  navText: [
    "<i class='fas fa-chevron-left'></i>",
    "<i class='fas fa-chevron-right'></i>",
  ],
  margin: 20,
  loop: true,
  items: 1,
});

$(".slider--carousel").owlCarousel({
  autoplay: false,
  dots: true,
  nav: true,
  navText: [
    "<i class='fas fa-chevron-left'></i>",
    "<i class='fas fa-chevron-right'></i>",
  ],
  margin: 15,
  loop: true,
  responsiveClass: true,
  responsive: {
    0: {
      items: 1,
    },
    800: {
      items: 2,
    },
  },
});

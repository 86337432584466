/* ==========================================================================
Imports
========================================================================== */
/* eslint-disable */
const LazyLoad = require("vanilla-lazyload");

const lazyLoadInstance = new LazyLoad({
  elements_selector: ".lazy",
});

const activeLocale = CCM_ACTIVE_LOCALE;

export default function PageListPlus(enableScroll = true) {
  this.enableScroll = !enableScroll ? false : enableScroll;

  /* ==========================================================================
  Page List Plus form submit method
  ========================================================================== */
  this.submit = (plp) => {
    const bID = plp.dataset.bid;
    const cID = plp.dataset.cid;
    const reloadURL = plp.querySelector(".reloadURL").value;
    const form = document.getElementById(`sbs_plp_form_${bID}`);
    const formData = new FormData(form);
    const serializedFormData = new URLSearchParams(formData).toString();
    const url =
      reloadURL +
      "?locale=" +
      activeLocale +
      "&bID=" +
      bID +
      "&cID=" +
      cID +
      "&ccm_paging_p=1&" +
      serializedFormData;

    const activeFilters = this.deparam(serializedFormData);

    // Set active filters
    if (activeFilters) {
      // setCookie('plpActiveFilters', JSON.stringify(activeFilters), 1);
    }

    this.get(url, plp);
    this.enableScroll && plp.scrollIntoView();
  };
  /* ==========================================================================
  Page List Plus get filtered data method
  ========================================================================== */
  this.get = async (url, plp) => {
    const bID = plp.dataset.bid;

    await fetch(url, {
      method: "GET",
    })
      .then((response) => response.text())
      .then((html) => {
        // Create newDom element for reference
        const newDom = document.createElement("div");
        newDom.innerHTML = html;

        const templateResultAreas = [
          `.ccm-block-page-list-pages[data-bID="${bID}"]`,
          ".pagination",
        ];
        for (let i = 0; i < templateResultAreas.length; i++) {
          const element = plp.querySelector(templateResultAreas[i]);

          if (element) {
            const newHtml = newDom.querySelector(templateResultAreas[i]);
            // element.innerHTML = newHtml.innerHTML;
            this.setInnerHTML(element, newHtml.innerHTML);
          }
        }

        // Call paginate again after DOM re-initialization for addEventListener to work
        this.paginate(plp);

        // Add fade to 0.5, 1

        // Hide loader
        const spinner = plp.querySelector(".spinner");
        if (spinner) {
          spinner.classList.remove("show");
        }

        if (lazyLoadInstance) {
          lazyLoadInstance.update();
        }

        // Close filter div and reset button
        $("body.filter--open").removeClass("filter--open");
        $(".hamburger--filter.hamburger--filter-active").removeClass(
          "hamburger--filter-active"
        );
      })
      .catch((err) => {
        // Add fade to 0.5, 1
        // Hide loader

        console.warn(err);
      });
  };
  /* ==========================================================================
  Page List Plus pagination method
  ========================================================================== */
  this.paginate = (plp) => {
    const pagination = plp.querySelector(".pagination");

    if (pagination) {
      const paginationLink = pagination.querySelectorAll("a");

      paginationLink.forEach((link) => {
        link.addEventListener("click", (e) => {
          e.preventDefault();

          const url = e.target.href;

          // Add fade to 1, 0.5

          // Show loader
          const spinner = plp.querySelector(".spinner");
          if (spinner) {
            spinner.classList.add("show");
          }

          this.get(url, plp);
          this.enableScroll && plp.scrollIntoView();
        });
      });
    }
  };
  /* ==========================================================================
  Page List Plus events
  ========================================================================== */
  this.submitEvents = (plp, treshold = 500) => {
    let timer;
    const waitTime = treshold;

    const execute = () => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        // Add fade to 1, 0.5

        // Show loader
        const spinner = plp.querySelector(".spinner");
        if (spinner) {
          spinner.classList.add("show");
        }

        this.submit(plp);
      }, waitTime);
    };

    const bID = plp.dataset.bid;
    const form = document.getElementById(`sbs_plp_form_${bID}`);

    // Text field
    form
      ?.querySelector("input:not([type='checkbox'][type='radio'])")
      ?.addEventListener("keyup", (e) => {
        if (e.keyCode !== 13) {
          execute();
        }
      });

    // Select field
    form?.querySelectorAll("select")?.forEach((select) => {
      select.addEventListener("change", (e) => {
        execute();
      });
    });

    // Checkbox / radio field
    form
      ?.querySelectorAll("input[type='checkbox'], input[type='radio']")
      ?.forEach((input) => {
        input.addEventListener("change", (e) => {
          execute();
        });
      });

    // Reset button
    form
      ?.querySelector("button[type='reset']")
      ?.addEventListener("click", (e) => {
        form.reset();

        form?.querySelectorAll("input, select")?.forEach((input) => {
          // Reset text field
          input.value = "";
          // Reset checkbox / radio field
          input.removeAttribute("checked");
          // Reset select field
          input.selectedIndex = -1;
        });

        execute();
      });
  };

  this.setInnerHTML = (elm, html) => {
    elm.innerHTML = html;

    Array.from(elm.querySelectorAll("script")).forEach((oldScriptEl) => {
      const newScriptEl = document.createElement("script");

      Array.from(oldScriptEl.attributes).forEach((attr) => {
        newScriptEl.setAttribute(attr.name, attr.value);
      });

      const scriptText = document.createTextNode(oldScriptEl.innerHTML);
      newScriptEl.appendChild(scriptText);

      oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
    });
  };

  /* ==========================================================================
  Page List Plus initialization
  ========================================================================== */
  this.init = () => {
    const plpSubmitViaAjax = document.querySelectorAll(
      ".sbs_plp_submitViaAjax"
    );

    plpSubmitViaAjax.forEach((plp) => {
      if (plp.classList.contains("sbs_plp_receiveViaAjax")) {
        /* ==========================================================================
        Execute on submit
        ========================================================================== */
        const bID = plp.dataset.bid;
        const form = document.getElementById(`sbs_plp_form_${bID}`);

        form?.addEventListener("submit", (e) => {
          e.preventDefault();

          // Add fade to 1, 0.5

          // Show loader
          const spinner = plp.querySelector(".spinner");
          if (spinner) {
            spinner.classList.add("show");
          }

          this.submit(plp);
        });

        /* ==========================================================================
        Execute pagination
        ========================================================================== */
        this.paginate(plp);
      }
    });
  };

  /* ==========================================================================
  Page List Plus deparam
  ========================================================================== */
  this.deparam = (query) => {
    let pairs;
    let i;
    let keyValuePair;
    let key;
    let value;
    const map = {};

    // remove leading question mark if its there
    if (query.slice(0, 1) === "?") {
      query = query.slice(1);
    }
    if (query !== "") {
      pairs = query.split("&");
      for (i = 0; i < pairs.length; i += 1) {
        keyValuePair = pairs[i].split("=");
        key = decodeURIComponent(keyValuePair[0]);
        // key = key.split('[')[0];
        value =
          keyValuePair.length > 1
            ? decodeURIComponent(keyValuePair[1])
            : undefined;
        if (value) {
          map[key] = value;
        }
      }
    }
    return map;
  };
}

/* ==========================================================================
Page List Plus variables
========================================================================== */
const pageListPlus = new PageListPlus();
const plpSubmitViaAjax = document.querySelectorAll(".sbs_plp_submitViaAjax");

/* ==========================================================================
Page List Plus initialization on page load
========================================================================== */
pageListPlus.init();

/* ==========================================================================
Page List Plus submit events
========================================================================== */
plpSubmitViaAjax.forEach((plp) => {
  pageListPlus.submitEvents(plp);
});
/* eslint-enable */
